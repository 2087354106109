<template>
  <div>
    <div v-if="loading" uk-height-viewport>
      <div class="uk-height-1-1 uk-flex uk-flex-middle uk-flex-center" uk-height-viewport>
        <h3 class="uk-text-center">
          Lade Kunstobjekte, bitte warten ...<br>
          <span uk-icon="icon: cloud-download; ratio: 3" class="uk-margin-top uk-text-muted"></span>
        </h3>
      </div>
    </div>
    <div v-else>
      <div v-if="!hasAccess" >
        <div class="uk-background-muted uk-background-center-center uk-background-norepeat" style="background-image: url(images/Closed-Image.png);" >
          <div class="uk-height-1-1 uk-flex uk-flex-bottom uk-flex-right" uk-height-viewport>
            <h3 class="uk-text-center">Leider konnte keine entsprechende Galerie gefunden werden.</h3>
          </div>
        </div>
      </div>
      <div v-else-if="hasAccess && !showroomActive">
        <div class="uk-background-muted uk-background-center-center uk-background-norepeat" style="background-image: url(images/Closed-Image.png);">
          <div class="uk-height-1-1 uk-flex uk-flex-bottom uk-flex-right" uk-height-viewport>
            <h3 class="uk-text-center">Leider wurde der Showroom für diese Galerie nicht aktiviert.</h3>
          </div>
        </div>
      </div>
      <div v-else-if="artobjects.length > 0" uk-grid  uk-height-viewport="offset-top: true">
        <div class="uk-width-1-1@s uk-margin-top uk-margin-large-bottom">
          <div uk-grid>
            <div class="uk-width-1-1@s uk-text-center">
              <h1 class="uk-heading-line uk-text-center"><span>Showroom</span></h1>
              <img :src="logo" :alt="name" style="max-height: 100px; width: auto;">
              <div class="uk-text-large">{{ name }}</div>
            </div>
          </div>
          <div class="uk-margin-large-top" uk-slider>
            <div class="uk-position-relative uk-visible-toggle" tabindex="-1">
              <div class="uk-slider-container">
                <ul class="uk-slider-items uk-child-width-1-4@l uk-child-width-1-3@m uk-child-width-1-2@s uk-grid">
                  <li v-for="(artobject, index) in artobjects" :key="index">
                    <div class="uk-card uk-card-small uk-card-default">
                      <div class="uk-card-media-top uk-text-center" @click="showDetails(artobject)" style="cursor: pointer">
                        <img v-if="artobject.large != null" :src="artobject.large" :alt="artobject.name" style="height: 250px; width: auto;" uk-img>
                        <img v-else :src="artobject.photo" :alt="artobject.name" style="height: 250px; width: auto;" uk-img>
                      </div>
                      <div class="uk-card-body">
                        <p class="uk-text-bold uk-text-center">
                          {{ artobject.name }}
                        </p>
                        <p>
                          Künstler<br>
                          {{ artobject.artist }}
                        </p>
                      </div>
                      <div class="uk-card-footer uk-text-right">
                        <button class="uk-button uk-button-text" @click="showDetails(artobject)">Details anzeigen</button>
                      </div>
                    </div>
                  </li>
                </ul>
              </div>
              <div class="uk-hidden@s uk-dark">
                <a class="uk-position-center-left uk-position-small" href="#" uk-slidenav-previous uk-slider-item="previous"></a>
                <a class="uk-position-center-right uk-position-small" href="#" uk-slidenav-next uk-slider-item="next"></a>
              </div>

              <div class="uk-visible@s uk-dark">
                <a class="uk-position-center-left-out uk-position-small" href="#" uk-slidenav-previous uk-slider-item="previous"></a>
                <a class="uk-position-center-right-out uk-position-small" href="#" uk-slidenav-next uk-slider-item="next"></a>
              </div>

            </div>
            <!-- <ul class="uk-slider-nav uk-dotnav uk-flex-center uk-margin"></ul> -->
          </div>
        </div>
      </div>
      <div v-bind:caobject="currentArtobject" id="modal-full" class="uk-modal-full" uk-modal>
      <div class="uk-modal-dialog">
        <button class="uk-modal-close-full uk-close-large" type="button" uk-close></button>
        <div class="uk-grid-collapse uk-child-width-1-2@s uk-flex-middle" uk-grid>
          <div v-if="currentArtobject.xlarge != null" class="uk-background-contain uk-visible@m" v-bind:style="{'background-image': 'url(' + currentArtobject.xlarge + ')'}" uk-height-viewport></div>
          <div v-else class="uk-background-contain uk-visible@m" v-bind:style="{'background-image': 'url(' + currentArtobject.photo + ')'}" uk-height-viewport></div>
          <div class="uk-hidden@s uk-text-center">
            <img v-if="currentArtobject.xlarge != null" :src="currentArtobject.xlarge" :alt="currentArtobject.name" uk-img>
            <img v-else :src="currentArtobject.photo" :alt="currentArtobject.name" uk-img>
          </div>
          <div class="uk-padding-large">
            <h1>{{ currentArtobject.name }}</h1>
            <div v-if="currentArtobject.desc" v-html="currentArtobject.desc"></div>
            <ul class="uk-list">
              <li>Künstler: {{ currentArtobject.artist }}</li>
              <li v-if="currentArtobject.price && properties.includes('price')">Preis: {{ formatPrice(currentArtobject.price) }}</li>
              <li v-if="currentArtobject.format && properties.includes('format')">Format: {{ capitalizeFirstLetter(currentArtobject.format) }}</li>
              <li v-if="currentArtobject.dimensions && properties.includes('dimensions')">
                Abmessungen (B/H/T) in cm: {{ currentArtobject.dimensions.width }} / {{ currentArtobject.dimensions.height }} / {{ currentArtobject.dimensions.depth }}
              </li>
              <li v-if="currentArtobject.material && properties.includes('material')">Material: {{ currentArtobject.material }}</li>
              <li v-if="properties.includes('framed')">Gerahmt: <span v-if="currentArtobject.framed">ja</span><span v-else>nein</span></li>
              <li v-if="currentArtobject.creation_date && properties.includes('creation_date')">Erstellungsdatum: {{ currentArtobject.creation_date }}</li>
            </ul>
            <button class="uk-button uk-button-default  uk-modal-close" type="button">Schliessen</button>
          </div>
        </div>
      </div>
    </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
// import HelloWorld from '@/components/HelloWorld.vue'

import axios from 'axios'
import UIkit from 'uikit'
// import { useRoute } from 'vue-router'

export default {
  name: 'Home',
  data () {
    return {
      artobjects: [],
      logo: null,
      name: null,
      hasAccess: false,
      showroomActive: false,
      currentArtobject: {},
      caobject: {},
      loading: false,
      properties: []
    }
  },
  methods: {
    async fetchArtobjects (key) {
      this.loading = true
      let mainUrl = 'https://backend.glry.io'
      if (window.location.host === 'stage-showroom.myglry.de') {
        mainUrl = 'https://stage.glry.io'
      }
      await axios.get(mainUrl + '/showroom?key=' + key)
        .then(response => {
          if (response.status === 200) {
            this.logo = response.data.logo
            this.name = response.data.name
            document.title = this.name + ' - Showroom'
            this.properties = response.data.showroom_properties
            if (response.data.objects.length > 0) {
              this.artobjects = response.data.objects
              this.hasAccess = true
              this.showroomActive = true
            } else {
              this.hasAccess = true
            }
            this.loading = false
          }
        })
        .catch(err => {
          console.log(err)
          this.loading = false
        })
      this.loading = false
    },
    showDetails (artobject) {
      this.currentArtobject = artobject
      console.log(this.currentArtobject)
      UIkit.modal('#modal-full').show()
    },
    capitalizeFirstLetter (string) {
      return string.charAt(0).toUpperCase() + string.slice(1)
    },
    formatPrice (price) {
      return new Intl.NumberFormat('de-DE').format(price) + ' €'
    }
  },
  created () {
    const key = this.$route.params.key
    if (key) {
      this.fetchArtobjects(key)
    }
  }
}
</script>
