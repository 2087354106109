import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import Error404 from '@/views/Error404'

Vue.use(VueRouter)

const routes = [
  {
    path: '/:key',
    name: 'Home',
    component: Home,
    props: true
  },
  {
    path: '/',
    name: 'Home',
    component: Home,
    props: true
  },
  {
    path: '/:catchAll(.*)*',
    component: Error404
  }
]

const router = new VueRouter({
  routes
})

export default router
